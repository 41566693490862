@import '~@myap/ui-library/sass/queries-mixins.scss';

.student-timeline {
  min-height: 100px;

  > button {
    top: 0;
  }
}

.timeline-container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 24px;
}

.timeline-header {
  line-height: 48px;

  @include mobile {
    line-height: normal;
  }
}

.event-assignment {
  .event-course {
    > div {
      text-transform: uppercase;
      margin: 5px 0;
    }

    > ul {
      margin-bottom: 1.5em;
    }
  }

  h4 {
    font-family: 'Roboto Bold', sans-serif;
    font-size: 2em;
    padding-left: 20px;
    margin-top: 0;
    line-height: normal;

    @include mobile {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h5 {
    font-size: 1.1em;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-top: 1.5em;
    }

    & ~ div {
      margin-bottom: 0.5em;
    }
  }

  .no-events {
    line-height: 2.2em;
  }
}

.toggle-button {
  border: 1px solid #000;
  background: transparent;
  font-family: 'Roboto Bold', sans-serif;
  height: 48px;
  position: relative;
  padding: 0 20px;
  margin-bottom: 24px;
  line-height: normal;
  white-space: nowrap;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    cursor: pointer;
    background-color: #e0f2ff;
    text-decoration: underline;
  }

  &:disabled {
    background-color: #324dc7;
    color: #fff;
  }

  i {
    top: 16px;
    left: 10px;
    position: absolute;
  }

  & + .toggle-button {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }

  @include mobile {
    width: 50%;
    margin-bottom: 20px;
    padding-left: 25px;

    i {
      left: 6px;
    }
  }
}

.course-anchor {
  border: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #0077c8;
  height: 75px;
  z-index: 1001;
  color: white;

  i {
    display: block;
  }

  &:hover,
  &:focus {
    span {
      text-decoration: underline;
    }
  }
}

.course-filter {
  margin-bottom: 2em;
  position: relative;

  button {
    width: 350px;

    @include small-format {
      width: 100%;
    }
  }

  > button {
    background: #f0f0f0;
    border: 1px solid #d9d9d9;
    height: 50px;
    line-height: 50px;
    text-align: left;
    color: #505050;
    padding: 0 0 0 9px;

    i {
      float: right;
      border-left: 1px solid #d9d9d9;
      height: 49px;
      line-height: 50px !important;
      margin-top: -1px;
      font-weight: 700 !important;
      font-size: 0.7em;
      width: 38px;
      text-align: center;
    }

    &:hover,
    &:focus {
      border: 1px solid #0077c8;
    }
  }

  ul {
    position: absolute;
    left: 0;
    top: 50px;
    list-style: none;
    padding-left: 0;
    z-index: 1001;

    @include small-format {
      width: 100%;
    }

    button {
      background: white;
      border: 1px solid #d9d9d9;
      border-top-color: #fff;
      text-align: left;
      padding: 15px 30px 15px 9px;
      line-height: normal;
      position: relative;

      i {
        position: absolute;
        right: 9px;
        color: #0077c8;
      }

      &:hover,
      &:focus {
        border: 1px solid #0077c8;
      }
    }
  }
}

.timeline-teaser {
  text-align: right;
  padding: 8px 0;
  font-weight: bold;
}
