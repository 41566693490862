@import "~@myap/ui-library/sass/queries-mixins.scss";

.resource-group {
  margin-top: 31px;

  @include mobile {
    margin-top: 0;
  }

  h3 {
    font-size: 1.3em;
    margin-bottom: 5px;
  }

  .resource-links {
    list-style: none;
    padding-left: 0;

    .resource {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #333;
      padding: 10px 0;

      a {
        font-weight: bold;
        color: #333;
      }

      .icon {
        margin-left: auto;
      }

      &.last {
        border-bottom: none;
      }
    }
  }
}
