@import '~@myap/ui-library/sass/queries-mixins.scss';

.school-toggle-container {
  display: flex;
  align-items: baseline;

  @include mobile {
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 20px;
  }

  @include tablet {
    justify-content: flex-start;
  }

  @include large-format {
    justify-content: flex-start;
  }

  margin-top: 20px;

  > div {
    > span {
      > span {
        color: #fff !important;
      }
    }

    > a {
      color: #fff !important;
    }
  }

  .sample {
    display: inline-block;
    margin-right: 20px;
    color: #fff;
    font-weight: bold;
  }
}

.education-period {
  @include mobile {
    display: block;
  }

  @include tablet {
    display: inline-block;
  }

  @include large-format {
    display: inline-block;
    margin-top: 16px;
  }

  color: #fff;

  @include mobile {
    margin: 10px 0;
    line-height: normal;
  }
}
