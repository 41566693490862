@import "~@myap/ui-library/sass/queries-mixins.scss";

.banner {
  padding-top: 35px;
  padding-bottom: 35px;

  h1 {
    color: #fff;
  }

  .header {
    margin-bottom: 0;
    letter-spacing: normal;

    @include mobile {
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .sub-header {
    margin-top: 15px;
    color: #fff;

    @include mobile {
      margin-bottom: 15px;
    }
  }
}
