.action-link {
  display: block;
  padding: 15px 0 15px 15px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  text-align: left;

  &.pre-ap {
    border-bottom: none;
  }
}

.course-resources {
  display: inline-block;
  width: 50%;
  border-right: 1px solid #ccc;
}

.assignments {
  display: inline-block;
  width: 50%;

  &.full-width {
    width: 100%;
  }
}

.event-links {
  padding: 15px;
  background-color: #eee;

  .event-link {
    display: block;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}

.no-wrap {
  white-space: nowrap;

  > span {
    font-size: 0.9em;
  }
}
