@import '~@myap/ui-library/sass/queries-mixins.scss';

.enrollment-section {
  padding: 15px;
  border-bottom: 1px solid #ccc;

  h4 {
    font-family: Roboto, sans-serif;
  }
}

.learn-more-link {
  padding: 15px;
  display: inline-block;
}

.progress-container {
  display: flex;
  justify-content: flex-end;

  @include small-format {
    border-top: 1px solid #ccc;
    margin: 20px -7.5px 0;
    padding: 20px 15px 0;
    justify-content: flex-start;
  }
}

.progress-item {
  margin-left: 40px;

  &:first-of-type {
    margin-left: 0;
  }

  @include small-format {
    text-align: left;
  }

  .progress-count,
  .progress-title {
    display: inline-block;
  }

  .progress-count {
    font-size: 2rem !important;
    line-height: 2rem;
    margin-right: 8px;
    font-weight: 700;

    > a {
      font-size: inherit !important;
    }
  }

  .progress-title {
    font-size: 0.9rem !important;
    line-height: 1.1em;
    text-align: left;
  }
}

.assignments {
  padding: 15px;
  border-bottom: 1px solid #ccc;

  .assignments-table {
    width: 75%;
    margin-bottom: 15px;

    @include small-format {
      width: 100%;
    }

    th,
    td {
      padding: 10px 0 10px 10px;
      vertical-align: top;

      &:first-child {
        padding-left: 0;
        width: 70%;
      }
    }

    thead th {
      font-family: Roboto, sans-serif;
    }

    tbody th {
      font-weight: 400;
    }
  }

  .past-due {
    color: rgb(170 2 2);
  }

  .assignment-not-open {
    color: #757575;
  }
}

.status-container {
  padding: 1em 15px;
  background-color: #eee;

  @include small-format {
    display: block;

    > div {
      padding: 0;
    }
  }

  &.status-digital {
    .inprogress-status {
      text-align: center;

      h5 {
        margin-top: 1em;
      }

      p {
        margin: 1em 0 !important;
      }
    }
  }

  h5 {
    margin-bottom: 1em;
    white-space: nowrap;

    @include small-format {
      margin-bottom: 0.5em;
    }
  }

  .exam-complete-status {
    text-align: center;
    flex-grow: 1 !important;

    h6 {
      margin-bottom: 0.5em;
    }

    @include desktop {
      a::before {
        content: ' ';
        display: block;
      }
    }

    @include small-format {
      text-align: left;
    }
  }

  .exam-status {
    h5 {
      display: inline-block;
      margin-right: 10px;
    }

    .exam-status-content {
      margin-top: 0.5em;
      text-align: center;

      > *,
      a {
        font-size: 0.875rem !important;
      }

      .registration-status {
        font-style: italic;
      }

      button {
        margin-top: 1em;
        margin-bottom: 0.5em;
      }

      h6 {
        font-weight: 700;
        margin-top: 1.5em;
        white-space: nowrap;
      }
    }
  }

  .install-status {
    a {
      display: inline-block;
      margin-bottom: 1em;
    }
  }

  .practice-status {
    a {
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    button {
      font-family: Roboto, sans-serif;
      font-weight: 700;
      padding: 0;
      text-align: left;
      white-space: nowrap;
      border: none;

      &:disabled {
        color: #777;
        cursor: default;
      }
    }

    div:first-of-type {
      margin-bottom: 1em;
    }
  }
}

.change-status {
  font-size: 0.875em;
  font-weight: bold;
  border: 0;
  text-align: left;
  display: block;
  margin: 1em auto;

  &:focus {
    outline: solid 1px #0077c8;
    outline-offset: 2px;
  }
}

:global(.cb-small-font) {
  * {
    font-size: inherit !important;
  }
}
