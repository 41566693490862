@import '~@myap/ui-library/sass/queries-mixins.scss';

.list-type-document a::after {
  content: '\e914' !important;
}

.parent-panel {
  border-top: none !important;
  border-bottom: none !important;
  margin: 4px 0 !important;
}

:global(.cb-accordion.parent > .panel-group) {
  margin-bottom: 0;
}

.hi-transactionband-flex {
  margin-bottom: 48px;

  :global(.cb-hi-transactions-content) {
    margin-left: 80px;
    margin-top: 5px;
    display: block;
    min-height: 48px;

    @include mobile {
      min-height: 36px;
      margin-top: 15px;
    }
  }

  :global(.cb-hi-transactions-image) i {
    background-color: #0077c8;
    color: white;
    border-radius: 50%;
    padding: 18px;
    font-size: 1.6em;
    position: absolute;
    min-width: 60px;
    min-height: 60px;
  }

  :global(.cb-hi-transactions) {
    @include large-format {
      height: 100px;
    }
  }
}

.event-card-year {
  min-height: 0.8571428571428571em;
}

.busy-button {
  position: relative;

  .busy-loader {
    position: absolute;
    right: 8px;
    top: 13px;
  }
}

.event-cards {
  ul {
    margin-top: 0.5em;
  }

  .event-card-action-link {
    &:hover,
    &:focus {
      text-decoration: none;

      > span {
        text-decoration: underline;
      }
    }

    i {
      font-size: 0.9em;
      padding-left: 5px;
    }
  }

  &:global(.cb-event-cards-vertical) {
    :global(.cb-event-info) {
      a {
        color: #006eb8;
      }
    }
  }
}

.question-tooltip {
  display: inline-block !important;
  height: 1.75em !important;
  width: 1.75em !important;
  font-size: 0.75em !important;
  vertical-align: middle;

  &::before {
    color: #0077c8 !important;
    top: calc(0.75em / 2) !important;
    left: calc(0.75em / 2) !important;
  }

  &::after {
    height: 1.75em !important;
    width: 1.75em !important;
    background-color: #fff !important;
    border: 1px solid #0077c8;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    &::before {
      color: #fff !important;
    }

    &::after {
      background-color: #0077c8 !important;
    }
  }
}

.icon-tooltip {
  white-space: normal;
}

.link-with-icon {
  font-weight: 700;
  padding: 0;
  border: none;
  white-space: nowrap;

  i {
    font-size: 0.875em !important;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;

    &.icon-left {
      margin-right: 5px;
      margin-left: 0;
    }
  }
}

.alert {
  position: relative;
  font-weight: 700;
  font-style: italic;
  padding-left: 22px;
  display: inline-block;

  &.alert-offset {
    margin-left: -22px;
  }

  i {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}

.icon-exclamation {
  display: inline-block !important;
  height: 1.05em !important;
  width: 1.05em !important;

  &::before {
    left: 3px !important;
    top: 3px !important;
    font-size: 0.7em;
    font-weight: bold;
  }

  &::after {
    background: #e57200 !important;
    height: 1.05em !important;
    width: 1.05em !important;
  }
}
