@import "~@myap/ui-library/sass/queries-mixins.scss";

.progress-slider-container {
  position: relative;
}

.progress-slider-content {
  margin-left: 30px;
  margin-right: 30px;
}

.btn-left,
.btn-right {
  position: absolute;
  background: transparent;
  border: none;
  font-size: 2em !important;
  color: #0077c8;
  top: 39px;

  &:disabled,
  &:disabled {
    color: #ccc;
    cursor: auto;
  }

  &:not(:disabled):focus,
  &:not(:disabled):focus {
    color: #009cde;
    outline: solid 1px #0077c8;
    outline-offset: 2px;
  }

  &:not(:disabled):hover,
  &:not(:disabled):hover {
    color: #009cde;
  }

  @include mobile {
    width: auto !important;
  }
}

.btn-left {
  left: -15px;
}

.btn-right {
  right: -15px;
}
