@import '~@myap/ui-library/sass/queries-mixins.scss';

.courses-empty-panel h3 {
  font-size: 1.5em !important;
  font-family: 'Roboto Slab', sans-serif;
  margin-bottom: 10px;
}

.courses-empty-panel > div {
  padding: 25px;
}

.courses-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @include small-format {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-name {
    font-weight: bold;
  }

  .course-totals {
    margin-left: auto;

    @include small-format {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      margin-left: 0;
    }

    @include mobile {
      flex-direction: column;
    }

    .total {
      display: inline-flex;
      align-items: center;
      font-family: Roboto, sans-serif;
      min-width: 150px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      @include small-format {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      @include mobile {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: auto;
        margin-top: 15px;
        margin-right: 15px;
      }

      > div {
        @include mobile {
          display: block;
        }

        &.count {
          font-size: 2em;
          margin-right: 15px;
          font-weight: 700;

          @include mobile {
            margin-right: 15px;
            font-size: 1.8em;
          }
        }

        &.description {
          line-height: normal;
          font-size: 0.9em;
          margin-top: -5px;

          @include small-format {
            margin-top: 8px;
          }

          @include mobile {
            margin-top: -5px;
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
