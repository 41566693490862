@import '~@myap/ui-library/sass/queries-mixins.scss';

.content-container {
  margin: 2em 0 4em;
  display: flex;

  @include small-format {
    flex-flow: row wrap;
    justify-content: space-around;
    clear: both;
  }

  > div {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    @include tablet {
      flex: 1 49%;
      margin-bottom: 10px;

      &:nth-child(even) {
        margin-right: 0;
      }
    }

    @include mobile {
      flex: 1 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

.content-box-main {
  border: 1px solid #ccc;
  padding: 25px;
  margin-bottom: 3em;

  h2,
  h3 {
    margin-bottom: 1em;
    font-family: Roboto, sans-serif;
  }

  a,
  button {
    text-align: center;
    width: 75%;

    @include mobile {
      width: 100%;
      white-space: normal;
      line-height: normal;
    }
  }
}

.content-box-support,
.content-support {
  margin-bottom: 3em;
  margin-top: 2em;

  h2,
  h3 {
    margin-bottom: 1em;
    font-family: Roboto, sans-serif;
  }

  h5 {
    margin-bottom: 1.5em;
  }

  > div:last-child > p:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    text-align: center;

    button {
      width: 100%;
      white-space: wrap;
    }
  }
}

.content-box-support {
  border: 1px solid #ccc;
  padding: 25px;
}

ul.callout-links {
  list-style: none;
  padding-left: 0;
  margin-top: 24px;
  margin-bottom: 0;

  li {
    padding-bottom: 1em;

    a {
      font-weight: 700;

      &::after {
        font-family: 'CB Icons 4_5_30';
        font-weight: 400;
        font-size: 0.9em;
        content: '\e975';
        margin-left: 5px;
      }
    }
  }
}
