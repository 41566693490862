.preview-banner {
  background: #555;
  color: #fff;
  padding: 20px 0;
  box-shadow: 0 1px 1px #666;
  line-height: normal;

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .preview-banner-content {
    margin-top: 20px;
    margin-bottom: 10px;

    p {
      min-height: 50px;
    }

    button {
      vertical-align: middle;
      line-height: normal;

      &:disabled {
        opacity: 0.5;

        &:hover,
        &:focus {
          text-decoration: none;
          cursor: auto;
        }
      }
    }

    span {
      margin: 0 10px;
      line-height: 2em;
    }
  }
}
