@import '~@myap/ui-library/sass/queries-mixins.scss';

.classroom-navigation {
  height: 100px;
  display: flex;
  align-items: flex-start;

  @include mobile {
    justify-content: center;
  }

  @include tablet {
    justify-content: flex-end;
  }

  @include desktop {
    justify-content: flex-end;
  }

  @include oversize {
    justify-content: flex-end;
  }
}

.classroom-list {
  margin: -24px;
  padding: 0;
  list-style: none !important;

  li {
    padding: 0;
    border-bottom: 1px solid #d9d9d9;

    &:hover {
      background-color: #f5f4f2;
    }

    a {
      display: block;
      padding: 16px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
