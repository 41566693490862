@import '~@myap/ui-library/sass/queries-mixins.scss';

.course-card {
  margin-bottom: 40px;

  // @include mobile {
  //   margin-bottom: 60px;
  // }

  .header {
    display: block;
    position: relative;
    background-color: #000;
    padding: 130px 15px 15px;
    margin-top: 40px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    .icon-wrapper {
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -96px;
      height: 172px;
      width: 192px;
      background-color: #000;
      border-radius: 50%;

      .icon {
        position: absolute;
        top: 15px;
        left: 25px;
        height: 142px;
        width: 142px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    h3 {
      text-align: center;
      color: #fff;
      margin-bottom: 15px;
      height: 3em;
    }

    h4 {
      color: #fff;
    }

    .go-classroom {
      display: inline-block;
      padding: 10px 15px;
      background-color: #000;
      color: #fff;
      font-weight: bold;
      border-radius: 999px;
      &.hidden {
        opacity: 0;
        // height: 37px;
      }
    }
  }

  .content {
    border-style: solid;
    border-color: #ccc;
    border-width: 0 1px 1px;

    .status-container {
      background-color: #eee;

      .exam-status {
        .exam-day {
          h5 {
            font-family: 'Roboto Bold';
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }

  $pink: #af2d8a;
  $darkblue: #006298;
  $mediumblue: #5570a7;
  $lightblue: #0077c8;
  $aqua: #269c90;
  $green: #006c31;
  $orange: #fbae25;
  $darkorange: #e57200;
  $salmon: #e8796d;
  $purple: #551c75;
  $darkpurple: #52004a;

  // US History
  &.course-1 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/AM HIST.png');
          top: 20px;
          left: 40px;
        }
      }
    }
  }

  // Art History
  &.course-2 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/ART HIST.png');
          top: 15px;
        }
      }
    }
  }

  // Studio Art Drawing
  &.course-3 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/DRAW.png');
          top: 20px;
        }
      }
    }
  }

  // Studio Art 2D
  &.course-4 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/2D ART.png');
          top: 25px;
        }
      }
    }
  }

  // Studio Art 3D
  &.course-5 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/3D ART.png');
          left: 29px;
        }
      }
    }
  }

  // Biology
  &.course-6 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/BIO.png');
          top: 15px;
          left: 20px;
        }
      }
    }
  }

  // Chemistry
  &.course-7 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/CHEM.png');
          top: 15px;
          left: 15px;
        }
      }
    }
  }

  // Computer Science A
  &.course-8 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/CSA.png');
          top: 15px;
        }
      }
    }
  }

  // Macroeconomics
  &.course-10 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/MACRO.png');
          top: 15px;
          left: 34px;
        }
      }
    }
  }

  // Microeconomics
  &.course-11 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/MICRO.png');
          top: 25px;
          left: 35px;
        }
      }
    }
  }

  // English Language and Composition
  &.course-12 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/ENG LANG.png');
          top: 20px;
        }
      }
    }
  }

  // English Literature and Composition (LATE)
  &.course-13 {
    .header {
      background-color: $lightblue;

      .icon-wrapper {
        background-color: $lightblue;

        .icon {
          background-image: url('../../../images/ENG LIT.png');
          top: 20px;
        }
      }
    }
  }

  // Environmental Science
  &.course-14 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/ENVI SCI.png');
          left: 38px;
        }
      }
    }
  }

  // European History
  &.course-15 {
    .header {
      background-color: $lightblue;

      .icon-wrapper {
        background-color: $lightblue;

        .icon {
          background-image: url('../../../images/EURO HIST.png');
        }
      }
    }
  }

  // French Language and Culture
  &.course-16 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/FRENCH.png');
        }
      }
    }
  }

  // Human Geography
  &.course-18 {
    .header {
      background-color: $orange;

      .icon-wrapper {
        background-color: $orange;

        .icon {
          background-image: url('../../../images/HUMAN GEO.png');
          top: 20px;
        }
      }

      h3,
      h4 {
        color: #333;
      }
    }
  }

  // German Language and Culture
  &.course-19 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/GERM.png');
        }
      }
    }
  }

  // United States Government and Politics
  &.course-20 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/US GOPO.png');
          left: 35px;
        }
      }
    }
  }

  // Comparative Government and Politics
  &.course-21 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/COMP GOPO.png');
          top: 20px;
        }
      }
    }
  }

  // Latin
  &.course-23 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/LATIN.png');
          top: 20px;
          left: 35px;
        }
      }
    }
  }

  // Calculus AB
  &.course-25 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/CALC AB.png');
          top: 15px;
          left: 30px;
        }
      }
    }
  }

  // Calculus BC
  &.course-26 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/CALC BC.png');
          top: 15px;
          left: 30px;
        }
      }
    }
  }

  // Music Theory
  &.course-27 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/MUSIC THEO.png');
          top: 20px;
        }
      }
    }
  }

  // Physics C: Mechanics
  &.course-29 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/PHYS C MECH.png');
          top: 13px;
          left: 30px;
        }
      }
    }
  }

  // Psychology
  &.course-30 {
    .header {
      background-color: $salmon;

      .icon-wrapper {
        background-color: $salmon;

        .icon {
          background-image: url('../../../images/PSYCH.png');
        }
      }
    }
  }

  // Spanish Language and Culture
  &.course-31 {
    .header {
      background-color: $orange;

      .icon-wrapper {
        background-color: $orange;

        .icon {
          background-image: url('../../../images/SPANISH LANG.png');
        }
      }

      h3,
      h4 {
        color: #333;
      }
    }
  }

  // Spanish Literature and Culture
  &.course-32 {
    .header {
      background-color: $salmon;

      .icon-wrapper {
        background-color: $salmon;

        .icon {
          background-image: url('../../../images/SPANISH LIT.png');
          left: 31px;
        }
      }
    }
  }

  // Statistics
  &.course-33 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/STAT.png');
          left: 31px;
        }
      }
    }
  }

  // World History Modern
  &.course-34 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/WORLD HIST.png');
        }
      }
    }
  }

  // Physics C Electricity and Magnetism
  &.course-78 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/PHYS C ELEC.png');
          top: 5px;
        }
      }
    }
  }

  // Italian Language and Culture
  &.course-86 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/ITAL.png');
        }
      }
    }
  }

  // Chinese Language and Culture
  &.course-87 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/CHINESE.png');
          top: 20px;
        }
      }
    }
  }

  // Japanese Language and Culture
  &.course-88 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/JPN.png');
        }
      }
    }
  }

  // Physics 1
  &.course-92 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/PHYS 1.png');
          top: 10px;
          left: 20px;
        }
      }
    }
  }

  // Physics 2
  &.course-93 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/PHYS 2.png');
          top: 10px;
        }
      }
    }
  }

  // Seminar
  &.course-94 {
    .header {
      background-color: $lightblue;

      .icon-wrapper {
        background-color: $lightblue;

        .icon {
          background-image: url('../../../images/SEM.png');
          top: 20px;
        }
      }
    }
  }

  // Research
  &.course-95 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/RES.png');
          top: 15px;
          left: 30px;
        }
      }
    }
  }

  // Computer Science Principles
  &.course-103 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/CSP.png');
        }
      }
    }
  }

  // Pre-AP Algebra 1
  &.course-104 {
    .header {
      background-color: $darkorange;

      .icon-wrapper {
        background-color: $darkorange;

        .icon {
          background-image: url('../../../images/PRE ALG1.png');
          top: 25px;
        }
      }
    }
  }

  // Pre-AP Algebra 2
  &.course-115 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/PRE ALG2.png');
          top: 25px;
        }
      }
    }
  }

  // Pre-AP Biology
  &.course-105 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/PRE BIO.png');
          top: 16px;
        }
      }
    }
  }

  // Pre-AP English 1
  &.course-106 {
    .header {
      background-color: $pink;

      .icon-wrapper {
        background-color: $pink;

        .icon {
          background-image: url('../../../images/PRE ENG1.png');
          top: 16px;
          left: 32px;
        }
      }
    }
  }

  // Pre-AP World History and Geography
  &.course-107 {
    .header {
      background-color: $darkorange;

      .icon-wrapper {
        background-color: $darkorange;

        .icon {
          background-image: url('../../../images/PRE WORLD HIST.png');
          top: 20px;
        }
      }
    }
  }

  // Pre-AP Dance
  &.course-108 {
    .header {
      background-color: $darkblue;

      .icon-wrapper {
        background-color: $darkblue;

        .icon {
          background-image: url('../../../images/PRE DANCE.png');
          top: 10px;
        }
      }
    }
  }

  // Pre-AP Theatre
  &.course-109 {
    .header {
      background-color: $lightblue;

      .icon-wrapper {
        background-color: $lightblue;

        .icon {
          background-image: url('../../../images/PRE THEA.png');
          top: 16px;
        }
      }
    }
  }

  // Pre-AP Visual Arts
  &.course-110 {
    .header {
      background-color: $mediumblue;

      .icon-wrapper {
        background-color: $mediumblue;

        .icon {
          background-image: url('../../../images/PRE ART.png');
          top: 10px;
          left: 33px;
        }
      }
    }
  }

  // Pre-AP Music
  &.course-111 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/PRE MUSIC.png');
          top: 12px;
        }
      }
    }
  }

  // Pre-AP English 2
  &.course-112 {
    .header {
      background-color: $purple;

      .icon-wrapper {
        background-color: $purple;

        .icon {
          background-image: url('../../../images/PRE ENG2.png');
          top: 15px;
          left: 20px;
        }
      }
    }
  }

  // Pre-AP Chemistry
  &.course-113 {
    .header {
      background-color: $green;

      .icon-wrapper {
        background-color: $green;

        .icon {
          background-image: url('../../../images/PRE CHEM.png');
          top: 16px;
          left: 30px;
        }
      }
    }
  }

  // Pre-AP Geometry and Statistics
  &.course-114 {
    .header {
      background-color: $aqua;

      .icon-wrapper {
        background-color: $aqua;

        .icon {
          background-image: url('../../../images/PRE GEO STATS.png');
          top: 5px;
          left: 30px;
        }
      }
    }
  }

  // AP African American Studies
  &.course-116 {
    .header {
      background-color: $green;
      .icon-wrapper {
        background-color: $green;
        .icon {
          background-image: url('../../../images/AFAMST.png');
          top: 15px;
          left: 25px;
        }
      }
    }
  }

  // AP Precalculus
  &.course-117 {
    .header {
      background-color: $darkpurple;
      .icon-wrapper {
        background-color: $darkpurple;
        .icon {
          background-image: url('../../../images/PRECALC.png');
          top: 10px;
          left: 30px;
        }
      }
    }
  }

  // CK Networking
  &.course-121 {
    .header {
      background-color: $lightblue;
      .icon-wrapper {
        background-color: $lightblue;
        .icon {
          background-image: url('../../../images/CYBERNET.png');
          top: 10px;
          left: 37px;
        }
      }
    }
  }

  // CK Security
  &.course-122 {
    .header {
      background-color: $lightblue;
      .icon-wrapper {
        background-color: $lightblue;
        .icon {
          background-image: url('../../../images/CYBERSEC.png');
          top: 10px;
          left: 30px;
        }
      }
    }
  }
}
