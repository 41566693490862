.fake-disabled-link,
.fake-disabled-button {
  cursor: default !important;

  &:hover,
  &:focus {
    text-decoration: none !important;

    > span {
      text-decoration: none !important;
    }
  }
}

td,
th {
  .fake-disabled-link {
    cursor: default !important;

    &:hover,
    &:focus {
      text-decoration: underline !important;

      > span {
        text-decoration: underline !important;
      }
    }
  }
}

.fake-disabled-button {
  &:global(.cb-btn-primary) {
    background-color: #f0f0f0;
    color: #b2b2b2;
    opacity: 1;
  }
}
