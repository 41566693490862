.register-options {
  > p:first-child {
    font-family: 'Roboto Bold', sans-serif;
    font-size: 1.2em;
    line-height: normal;
  }

  .register-choice-container {
    margin: 2em 0 !important;

    &::after {
      content: ' ';
      display: table;
      clear: both;
    }

    > button {
      background: none;
      width: 50%;
      margin: 0;
      font-weight: 700;
      height: 70px;
      float: left;
      line-height: normal;
      border: 1px solid #666;

      &:first-child {
        margin-right: -1px;
      }

      &.selected,
      &:hover,
      &:focus {
        background: #eee;
      }
    }
  }
}

.register-success {
  text-align: center;
  margin: 40px 0 !important;

  > div {
    line-height: normal;
  }
}
