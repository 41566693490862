@import '~@myap/ui-library/sass/queries-mixins.scss';

.notifications-button {
  font-weight: 700;
  color: #505050;
  border: none;
  background: transparent;
  float: right;
  padding: 0;
  line-height: 60px;

  @include mobile {
    padding: 0;
    display: block;
    width: 100%;
    border-top: 1px solid #ccc;
    text-align: left;
  }

  & span + i {
    margin-left: 5px;

    @include mobile {
      float: right;
      line-height: inherit !important;
      font-size: 1em;
    }
  }

  &:not([disabled]):hover span,
  &:not([disabled]):focus span {
    text-decoration: underline;
  }

  .notifications-icon-megaphone {
    border-radius: 50%;
    background-color: #505050;
    padding: 9px;
    color: #fff;
    margin-right: 20px;

    @include mobile {
      padding: 5px;
      font-size: 0.75em;
      margin-right: 10px;
    }
  }
}

.notifications {
  padding: 15px 0;
  box-shadow: 0 4px 2px #ddd;
  width: 100%;

  &.notifications-dropdown {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 999;
  }

  @include mobile {
    top: 121px;
  }
}

.notification {
  @include large-format {
    margin: 0 auto;
    width: 75%;
  }
}
