.last-updated {
  text-align: right;

  p {
    color: #444;
    font-size: 0.9em;
  }
}

:global(.table-responsive) + .last-updated {
  margin-top: -1.5em;
}
