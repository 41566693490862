@import "~@myap/ui-library/sass/queries-mixins.scss";

.courses-container {
  margin: 0;

  h2 {
    margin-bottom: 0;
  }

  > div > :global(.panel-group):not(:empty) {
    margin-bottom: 3em;
    margin-top: 1em;
  }

  .totals {
    background: #e9e9e9;
    font-weight: 700;
  }

  table * {
    font-size: 1rem !important;
  }

  thead > tr > th:first-child {
    vertical-align: bottom;
    font-family: Roboto, sans-serif;
  }

  :global(.cb-accordion .panel-title) {
    position: relative;
  }

  .table-header-link {
    color: #fff !important;
    text-decoration: none !important;

    > span {
      text-decoration: underline;
      margin-right: 5px;
    }

    &:hover span,
    &:focus span {
      text-decoration: none;
    }

    i {
      font-size: 0.9rem !important;
    }
  }
}
