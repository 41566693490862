.registration {
  fieldset {
    margin-bottom: 30px;

    &:disabled {
      opacity: 0.75;
    }

    .input-sub {
      > div {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
        padding-left: 48px;
      }
    }

    p {
      margin-bottom: 1em;
    }
    hr {
      margin: 1em 0;
    }
    fieldset {
      margin-top: 1.5em;
      legend {
        font-size: 16px;
        margin-bottom: 0.5em;
      }
    }

    :global(legend.cb-base-font) {
      border-bottom: none;
      margin-bottom: 5px;
    }
  }
}
