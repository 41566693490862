@import '~@myap/ui-library/sass/queries-mixins.scss';

.join-course-card-title {
  p {
    font-size: 1.5rem;
  }
}

.join-course {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 15px 0;

  @include mobile {
    button {
      width: 100%;
    }
  }
}

.enter-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;

  @media (min-width: 1px) and (max-width: 1247.98px) {
    width: 100%;
  }

  :global(.cb-input) {
    width: 60%;
    margin: 0 auto;
    max-width: 300px;

    @media (min-width: 1px) and (max-width: 767.98px) {
      width: 80%;
      max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1023.98px) {
      width: 80%;
    }

    > i {
      top: 26px !important;
    }
  }

  input {
    margin-bottom: 16px !important;
    padding-right: 12px !important;
    height: 70px;
    font-size: 3em;
    text-align: center;
    text-transform: uppercase;
  }

  :global(.cb-input-helper.cb-validation-error) {
    align-items: center !important;
    width: auto !important;
  }
}

.confirm-code {
  width: 60%;
  margin: 0 auto;

  @include mobile {
    width: 100%;
  }

  .code-box {
    width: 400px;
    height: 70px;
    font-size: 3em;
    border: 2px solid #ccc;
    line-height: 70px;
    padding: 0 15px;
    position: relative;
    margin: 0 auto 24px;
    text-align: center;

    i {
      top: 15px;
      right: 15px;
      position: absolute;
    }
  }

  h5 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  > div {
    margin-bottom: 0.5em;
  }

  > p {
    margin: 1.5em 0;
  }
}

.survey {
  fieldset {
    legend {
      border-bottom: 0;
      font-size: 1em;
      font-family: 'Roboto Bold', sans-serif;
      font-weight: bold;
      line-height: inherit;
    }

    margin-bottom: 2em;
  }
}

// .register {
//   form {
//     > div:last-child {
//       margin-top: 3em;
//       padding-top: 0;
//       border-top: 0;
//       margin-bottom: 2em !important;

//       &::before,
//       &::after {
//         clear: both;
//         content: ' ';
//         display: table;
//       }

//       p {
//         margin-bottom: 1em;
//       }

//       button {
//         margin-top: 0;
//         float: right;
//       }
//     }
//   }
// }

// .locked-save-button {
//   border-top: 1px solid #000;
//   margin-top: 60px;
//   padding-top: 30px;

//   &:not(.multi-button.mobile) {
//     button,
//     a {
//       padding-left: 60px;
//       padding-right: 60px;
//     }
//   }
//   &.multi-button {
//     button,
//     a {
//       margin-right: 10px;
//       margin-bottom: 10px;
//     }
//   }

//   &.mobile {
//     position: fixed;
//     width: 100%;
//     margin-top: 0;
//     padding: 30px 15px 30px 15px;
//     background: #fff;
//     bottom: 0;
//     left: 0;
//     z-index: 999;

//     &:not(.multi-button) {
//       button,
//       a {
//         width: 100%;
//       }
//     }
//   }
// }
// }

.save {
  text-align: center;
  line-height: normal;
  font-size: 2rem;
}

.save-loader {
  min-height: 130px;

  :global(.cb-loader) {
    &::after {
      content: 'saving' !important;
    }
  }
}
