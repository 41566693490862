.link-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.within-banner {
    li {
      a {
        color: #fff;
      }

      > span::after {
        color: #fff;
      }
    }
  }

  li {
    display: inline-block;

    a {
      font-weight: 700;
      list-style: none;

      &:hover,
      &:focus {
        text-decoration: none;
        cursor: pointer;

        span {
          text-decoration: underline;
        }
      }

      i {
        padding-left: 5px;
        font-size: 0.9em;
      }
    }

    > span::after {
      content: "|";
      padding: 0 15px;
    }
  }
}
